.react-dropdown-select {
  padding: 3px 0px !important;
  border-radius: 6px !important;
}

.react-dropdown-light-theme {
  border-color: var(--chakra-colors-gray-200) !important;
}

.react-dropdown-select-dropdown-handle > svg {
  width: 18px !important;
  height: 18px !important;
}
.react-dropdown-select-dropdown {
  border-radius: 6px !important;
}

.react-dropdown-light-theme > .react-dropdown-select-dropdown-handle,
.react-dropdown-dark-theme > .react-dropdown-select-dropdown-handle {
  position: absolute;
  right: 16px;
}

.react-dropdown-light-theme:hover,
.react-dropdown-light-theme:focus {
  border-color: var(--chakra-colors-blue-500);
  box-shadow: 0 0 0 2px #3182ce !important;
}

.react-dropdown-light-theme > .react-dropdown-select-content {
  padding: 0px 12px;
  color: var(--chakra-colors-gray-400);
}

/*Dark mode styles */
.react-dropdown-dark-theme {
  border-color: var(--chakra-colors-whiteAlpha-300) !important;
}

.react-dropdown-dark-theme:hover,
.react-dropdown-dark-theme:focus {
  border-color: var(--chakra-colors-blue-300);
  box-shadow: 0 0 0 2px #63b3ed !important;
}

.react-dropdown-dark-theme > .react-dropdown-select-content {
  padding: 0px 12px;
  color: var(--chakra-colors-whiteAlpha-500);
}
